import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
	baseURL: "",

	init() {
		Vue.use(VueAxios, axios);

		ApiService.baseURL = "http://api.prcweb.com.br/"; //"http://localhost:4000/"

		Vue.axios.defaults.baseURL = ApiService.baseURL;

		//Vue.axios.defaults.baseURL = ;
	},

	/**
	 * Set the default HTTP request headers
	 */
	setHeader() {
		Vue.axios.defaults.headers.common[
			"Authorization"
		] = `${JwtService.getToken()}`;
		Vue.axios.defaults.headers.common["Content-Type"] =
			"multipart/form-data";
	},

	query(resource, params) {
		return Vue.axios.get(resource, params).catch((error) => {
			throw new Error(`[KT] ApiService ${error}`);
		});
	},

	/**
	 * Send the GET HTTP request
	 * @param resource
	 * @param slug
	 * @returns {*}
	 */
	get(resource, slug = "") {
		return Vue.axios.get(`${resource}/${slug}`);
	},

	/**
	 * Set the POST HTTP request
	 * @param resource
	 * @param params
	 * @returns {*}
	 */
	form_post(resource, params) {
		const FormParams = new FormData();
		for (let key in params) {
			FormParams.append(key, params[key]);
		}

		return Vue.axios.post(`${resource}`, FormParams);
	},

	post(resource, params) {
		const FormParams = new FormData();
		for (let key in params) {
			if (Array.isArray(params[key])) {
				for (let arraykey in params[key]) {
					FormParams.append(key, params[key][arraykey]);
				}
			} else {
				FormParams.append(key, params[key]);
			}
		}

		for (var pair of FormParams.entries()) {
			console.log(pair[0] + ", " + pair[1]);
		}

		return Vue.axios.post(`${resource}`, FormParams);
	},
	/**
	 * Send the UPDATE HTTP request
	 * @param resource
	 * @param slug
	 * @param params
	 * @returns {IDBRequest<IDBValidKey> | Promise<void>}
	 */
	update(resource, slug, params) {
		return Vue.axios.put(`${resource}/${slug}`, params);
	},

	/**
	 * Send the PUT HTTP request
	 * @param resource
	 * @param params
	 * @returns {IDBRequest<IDBValidKey> | Promise<void>}
	 */
	put(resource, params) {
		const FormParams = new FormData();
		for (let key in params) {
			if (Array.isArray(params[key])) {
				for (let arraykey in params[key]) {
					FormParams.append(key, params[key][arraykey]);
				}
			} else {
				FormParams.append(key, params[key]);
			}
		}
		return Vue.axios.put(`${resource}`, FormParams);
	},

	/**
	 * Send the DELETE HTTP request
	 * @param resource
	 * @returns {*}
	 */
	delete(resource) {
		return Vue.axios.delete(resource);
	},

	uploadImage(img) {
		let config = {
			header: {
				"Content-Type": "image/png",
			},
		};
		return Vue.axios.post("etc/uploadImg", img, config);
	},

	anonUploadImg(img) {
		let config = {
			header: {
				"Content-Type": "image/png",
			},
		};
		return Vue.axios.post("etc/anonUploadImg", img, config);
	},
};

export default ApiService;

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
	errors: null,
	user: {},
	isAuthenticated: !!JwtService.getToken(),
};

const getters = {
	currentUser(state) {
		return state.user;
	},
	isAuthenticated(state) {
		return state.isAuthenticated;
	},
};

const actions = {
	[LOGIN](context, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.form_post("login/", credentials)
				.then(({ data }) => {
					context.commit(SET_AUTH, data.response);
					resolve(data.response.user);
				})
				.catch((error) => {
					if (error.response && error.response.data.errors) {
						context.commit(SET_ERROR, error.response.data.errors);
					} else {
						context.commit(SET_ERROR, [error.message]);
					}
					reject([error.message]);
				});
		});
	},
	[LOGOUT](context) {
		context.commit(PURGE_AUTH);
	},
	[REGISTER](context, credentials) {
		credentials["usu_role_id"] = 2;
		return new Promise((resolve, reject) => {
			ApiService.form_post("users", credentials)
				.then(({ data }) => {
					console.log(data);
					// context.commit(SET_AUTH, data);
					// resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[VERIFY_AUTH](context) {
		if (JwtService.getToken()) {
			ApiService.setHeader();
			ApiService.get("login")
				.then(({ data }) => {
					if (data.status) {
						context.commit(SET_AUTH, data.user);
					} else {
						context.commit(SET_ERROR, data.mensagem);
					}
				})
				.catch((error) => {
					context.commit(PURGE_AUTH);
					context.commit(SET_ERROR, error.message);
				});
		} else {
			context.commit(PURGE_AUTH);
		}
	},
	[UPDATE_USER](context, payload) {
		const { email, username, password, image, bio } = payload;
		const user = { email, username, bio, image };
		if (password) {
			user.password = password;
		}

		return ApiService.put("Users/addUser/", user).then(({ data }) => {
			context.commit(SET_AUTH, data);
			return data;
		});
	},
};

const mutations = {
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_AUTH](state, response) {
		state.isAuthenticated = true;
		let user = response.user;
		user["token"] = response.Authorization;
		state.user = user;
		state.errors = {};
		JwtService.saveToken(state.user.token);
	},
	[PURGE_AUTH](state) {
		state.user = {};
		state.errors = {};
		JwtService.destroyToken();
		state.isAuthenticated = false;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};

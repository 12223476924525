import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export default new VueRouter({
	mode: "history",
	routes: [
		{
			path: "/",
			redirect: "/dashboard",
			component: () => import("@/view/layout/Layout"),
			children: [
				{
					path: "/dashboard",
					name: "dashboard",
					component: () => import("@/view/pages/Dashboard.vue"),
				},
				{
					path: "/users",
					name: "users",
					redirect: "/users/list",
					component: () => import("@/view/pages/Users/Layout.vue"),
					children: [
						{
							path: "new",
							name: "users-new",
							component: () =>
								import("@/view/pages/Users/User.vue"),
						},
						{
							path: "edit/:id",
							name: "users-edit",
							component: () =>
								import("@/view/pages/Users/User.vue"),
						},
						{
							path: "list",
							name: "users-list",
							component: () => import("@/view/pages/Users.vue"),
						},
					],
				},
				{
					path: "/favoritos",
					name: "favoritos",
					component: () => import("@/view/pages/Favoritos.vue"),
				},
				{
					path: "/categorias",
					name: "categorias",
					redirect: "/categorias/list",
					component: () =>
						import("@/view/pages/Categorias/Layout.vue"),
					children: [
						{
							path: "new",
							name: "categorias-new",
							component: () =>
								import("@/view/pages/Categorias/Categoria.vue"),
						},
						{
							path: "edit/:id",
							name: "categorias-edit",
							component: () =>
								import("@/view/pages/Categorias/Categoria.vue"),
						},
						{
							path: "list",
							name: "categorias-list",
							component: () =>
								import("@/view/pages/Categorias.vue"),
						},
					],
				},
				{
					path: "/banners",
					name: "banners",
					redirect: "/banners/list",
					component: () => import("@/view/pages/Banners/Layout.vue"),
					children: [
						{
							path: "new",
							name: "banners-new",
							component: () =>
								import("@/view/pages/Banners/Banners.vue"),
						},
						{
							path: "edit/:id",
							name: "banners-edit",
							component: () =>
								import("@/view/pages/Banners/Banners.vue"),
						},
						{
							path: "list",
							name: "banners-list",
							component: () => import("@/view/pages/Banners.vue"),
						},
					],
				},
				{
					path: "/plans",
					name: "plans",
					redirect: "/plans/list",
					component: () => import("@/view/pages/Plans/Layout.vue"),
					children: [
						{
							path: "new",
							name: "plans-new",
							component: () =>
								import("@/view/pages/Plans/Plans.vue"),
						},
						{
							path: "edit/:id",
							name: "plans-edit",
							component: () =>
								import("@/view/pages/Plans/Plans.vue"),
						},
						{
							path: "list",
							name: "plans-list",
							component: () => import("@/view/pages/Plans.vue"),
						},
					],
				},
				{
					path: "/companies",
					name: "companies",
					redirect: "/companies/list",
					component: () =>
						import("@/view/pages/Companies/Layout.vue"),
					children: [
						{
							path: "new",
							name: "companies-new",
							component: () =>
								import("@/view/pages/Companies/Company.vue"),
						},
						{
							path: "edit/:id",
							name: "companies-edit",
							component: () =>
								import("@/view/pages/Companies/Company.vue"),
						},
						{
							path: "list",
							name: "companies-list",
							component: () =>
								import("@/view/pages/Companies.vue"),
						},
					],
				},
				{
					path: "/stores",
					name: "stores",
					redirect: "/stores/list",
					component: () => import("@/view/pages/Stores/Layout.vue"),
					children: [
						{
							path: "new",
							name: "stores-new",
							component: () =>
								import("@/view/pages/Stores/Store.vue"),
						},
						{
							path: "edit/:id",
							name: "stores-edit",
							component: () =>
								import("@/view/pages/Stores/Store.vue"),
						},
						{
							path: "list",
							name: "stores-list",
							component: () => import("@/view/pages/Stores.vue"),
						},
					],
				},
				{
					path: "/wizard",
					name: "wizard",
					component: () => import("@/view/pages/wizard/Wizard.vue"),
					children: [
						{
							path: "wizard-1",
							name: "wizard-1",
							component: () =>
								import("@/view/pages/wizard/Wizard-1.vue"),
						},
						{
							path: "wizard-2",
							name: "wizard-2",
							component: () =>
								import("@/view/pages/wizard/Wizard-2.vue"),
						},
						{
							path: "wizard-3",
							name: "wizard-3",
							component: () =>
								import("@/view/pages/wizard/Wizard-3.vue"),
						},
						{
							path: "wizard-4",
							name: "wizard-4",
							component: () =>
								import("@/view/pages/wizard/Wizard-4.vue"),
						},
					],
				},
			],
		},
		{
			path: "/error",
			name: "error",
			component: () => import("@/view/pages/error/Error.vue"),
			children: [
				{
					path: "error-1",
					name: "error-1",
					component: () => import("@/view/pages/error/Error-1.vue"),
				},
				{
					path: "error-2",
					name: "error-2",
					component: () => import("@/view/pages/error/Error-2.vue"),
				},
				{
					path: "error-3",
					name: "error-3",
					component: () => import("@/view/pages/error/Error-3.vue"),
				},
				{
					path: "error-4",
					name: "error-4",
					component: () => import("@/view/pages/error/Error-4.vue"),
				},
				{
					path: "error-5",
					name: "error-5",
					component: () => import("@/view/pages/error/Error-5.vue"),
				},
				{
					path: "error-6",
					name: "error-6",
					component: () => import("@/view/pages/error/Error-6.vue"),
				},
			],
		},
		{
			path: "/",
			component: () => import("@/view/pages/auth/Auth"),
			children: [
				{
					name: "login",
					path: "/login",
					component: () => import("@/view/pages/auth/Login"),
				},
				// {
				//   name: "register",
				//   path: "/register",
				//   component: () => import("@/view/pages/auth/Register")
				// }
			],
		},
		{
			path: "*",
			redirect: "/404",
		},
		{
			path: "/404",
			name: "404",
			component: () => import("@/view/pages/error/Error-1.vue"),
		},
	],
});
